.spinner-wrapper {
    display: none;
}

.spinner-label {
    margin-bottom: 0;
}

.spinner {
    width: 68px;
}

.spinner input {
    text-align: right;
}

.input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
}

.input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;
}

.input-group-btn-vertical > .btn:first-child {
    border-top-right-radius: 4px;
}

.input-group-btn-vertical > .btn:last-child {
    margin-top: -2px;
    border-bottom-right-radius: 4px;
}

.input-group-btn-vertical i {
    position: absolute;
    top: 0;
    left: 4px;
}
