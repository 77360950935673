.login-page {
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.0)), url("/bundles/app/img/login-background.jpg");
  background-size: auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 0 !important;
  background-position-y: 81px;
  background-position-x: center;
  min-height: 925px !important;
  

  @media (max-width: 768px) {
    background: none;
  }

  .row {
    margin: 0 !important;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .btn-mydimm, .btn-mydimm-alt {
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
  }

  .btn-mydimm {
    color: #4d4c4a;
    background-color: #f8b01b;
  }
  .btn-mydimm-alt {
    background-color: #4d4c4a;
    color: #f8b01b;
  }

  .facebook-logo {
    background-color: #4267B2;
    padding: 5px 8px;
    color: white;
    border-radius: 5px;
  }

  .google-logo {
    background-color: #DB4437;
    padding: 5px;
    color: white;
    border-radius: 5px;
  }

  .btn-gray {
    color: black;
    background-color: #ececec;
  }

  header {
    background-color: white;

    img {
      height: 80px;
    }

    h1 {
      font-size: 24px;
      font-weight: 400;
      margin: 0;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 1em;

      @media (max-width: 768px) {
        display: none;
      }
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
      flex-direction: column;

      img {
        max-height: 72px;
      }

      h1 {
        font-size: 20px;
        margin-top: 0;
        text-align: center;
      }

      .actions {
        margin-bottom: 1em;
      }
    }
  }

  .login-cols {
    display: flex;
    padding: 5rem;
    justify-content: space-between;
    align-items: center;
    max-width: 2000px;
    margin: auto;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .login-text {
    line-height: 2rem;
    color: white;
    padding: 6rem 5rem;
    text-shadow: 2px 2px 5px #4d4c4a;
    p, li {
      font-size: 1.8rem;
      margin: 2rem 0;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  section {
    background: white;
    border-radius: 10px;
    padding: 2em;
    width: 50vw;
    max-width: 550px;
  
    label {
      text-align: left;
    }

    .warn-msg {
      margin-bottom: 1em;
    }
  
    .head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2em;
      gap: 2em;

      @media (max-width: 768px) {
        display: none;
      }
  
      h2 {
        font-size: 22px;
      }
  
      img {
        max-height: 96px;
      }
    }
  
    .separator {
      &::before {
        content: "";
        background-color: #ababab;
        width: 100%;
        height: 1px;
        display: block;
        margin-bottom: -.75em;
      }
      span {
        background-color: white;
        padding: 0 1em;
        text-transform: uppercase;
      }
      text-align: center;
      margin: 2em 0;
      color: #ababab;
    }

    .mobile-head-img {
      display: none;
      margin: 0 auto;
    }

    .container {
      width: 100%;
    }

    @media (max-width: 768px) {
      background-color: #4d4c4aee;
      width: 100%;
      max-width: none;
      color: white;
      min-height: calc(100vh - 72px);
      margin: 0;
      border-radius: 0;
      padding: 0;

      .mobile-head-img {
        display: block !important;
        width: 100%;
      }

      .container {
        padding: 2em;

        small {
          color: #aaa;
        }
      }

      .separator {
        span {
          background-color: #4d4c4a;
          border-radius: 3px;
        }
      }

      a {
        color: #f8b01b;
      }
    }
  }
}
