/**
 * Viewer & Editor
 */
.viewer-top-tools {
    width: 100%;
    background-color: #3a3a3a;
    padding: 0.6em 0 0.5em 1em;
    border-radius: 5px 5px 0 0;
    display: inline-block;
    line-height: 2em;
}

.viewer-top-tools .lead {
    margin-bottom: 0;
    margin-right: 1em;
}

.viewer-top-tools .right-column {
    margin-top: -5px;
    padding-bottom: 5px;
}

.viewer-top-tools .text-muted {
    color: #fff;
}

.viewer-top-tools .text-muted small {
    font-size: 65%;
}

.element {
    cursor: pointer;
}

.element.not-selectable {
    cursor: not-allowed;
}

.element.not-selectable .list-group-item-text {
    display: none;
}

.list-remove-btn,
.servicelist-remove-btn,
.reprocess-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.left-panel .panel-icon {
    display: none;
}

.editing-plan-wrapper {
    display: none;
}

.furniture-detail {
    min-height: 600px;
    border: 1px solid #000;
    border-left: none;
    background-color: #efefef;
    margin-left: -30px;
    text-align: left;
}
@media screen and (max-width: 795px) {
    .furniture-detail {
        min-height: 300px;
    }
}
/**
 * Responsive for editor
 */
@media (max-width: 1265px) {
    .left-panel {
        position: absolute;
        left: -243px;
        top: 0;
        z-index: 999;
        width: 275px;
        -webkit-transition: left 0.7s linear;
        -ms-transition: left 0.7s linear;
        transition: left 0.7s linear;
    }

    .left-panel .panel {
        background-color: #f5c00f;
        border-right: 2px solid #333;
        -webkit-transition-delay: 1s;
        transition-delay: 0.7s;
    }

    .left-panel:hover, .left-panel:active {
        left: -25px;
    }

    .left-panel:hover .panel, .left-panel:active .panel {
        background-color: #fff;
        -webkit-transition: background-color 0.5s linear;
        -ms-transition: background-color 0.5s linear;
        transition: background-color 0.5s linear;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }

    .left-panel .panel-icon {
        position: absolute;
        display: block;
        right: 0;
        padding: 0.6em 0.8em 0.6em 1em;
        background-color: #f5c00f;
        border-radius: 21px;
        color: #000;
    }

    .right-panel {
        width: 100%;
    }

    footer {
        position: fixed;
        bottom: 0;
    }
}
/**
 * Other tools
 */
.flash-tools img {
    width: 18px;
    height: 18px;
}

.flash-tools button {
    cursor: pointer;
}
.project-title {
    padding-left: 1em;
}
/**
 * Bootstrap override
 */
/** Default **/
.details-panel .list-group-item:not(.active):not(.hardware-item) {
    opacity: 0.6;
}

.creating-element .list-group-item:not(.active):not(.hardware-item) {
    opacity: 1;
}

.list-group-item.active {
    border-left-width: 10px;
    color: #31708f;
    background-color: #d9edf7;
}

.list-group-item.list-group-item-disabled {
    border-left-width: 10px;
    color: #888888;
    background-color: #dddddd;
    border-color: #aaaaaa;
}

.list-group-item.active .list-group-item-text {
    color: #31708f;
}
/** Success **/
.list-group-item.list-group-item-success.active {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
}

.list-group-item.list-group-item-success.active .list-group-item-text {
    color: #3c763d;
}

.list-group-item.list-group-item-success.active:hover .list-group-item-text {
    color: #dff0d8;
}

.list-group-item.list-group-item-success.active:hover {
    background-color: #3c763d;
    color: #fff;
}
/** Warning **/
.list-group-item.list-group-item-warning.active {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
}

.list-group-item.list-group-item-warning.active .list-group-item-text {
    color: #8a6d3b;
}

.list-group-item.list-group-item-warning.active:hover .list-group-item-text {
    color: #fcf8e3;
}

.list-group-item.list-group-item-warning.active:hover {
    background-color: #8a6d3b;
    color: #fff;
}
/** Danger **/
.list-group-item.list-group-item-danger.active {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
}

.list-group-item.list-group-item-danger.active .list-group-item-text {
    color: #a94442;
}

.list-group-item.list-group-item-danger.active:hover .list-group-item-text {
    color: #f2dede;
}

.list-group-item.list-group-item-danger.active:hover {
    background-color: #a94442;
    color: #fff;
}

.creating-element .price {
    font-weight: bold;
    font-size: 1.5em;
}

.StripeElement {
    box-sizing: border-box;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement:hover {
    border-color: #F49B09;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
    border-color: #F49B09;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .btn-mydimm {
      background-color: #F49B09;
  }