/*

Bootstrap customization for MyDimm.com
By: Creacore.be

*/
@import '_variables';
@import 'login-page';

html {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    height: 100%;

    .navigation
    {
        border-bottom: 1px solid black;
        margin-bottom: 2em;
        
        nav {
            .nav-container {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
    
                .header-left {
                    justify-content: flex-start;
                }
                .header-center {
                    flex-grow: 1;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
                .header-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
    
                ul {
                    display: flex;
                    align-items: center;
                    margin: 0; 
                    
                    li {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        font-weight: 300;
                        position: relative;
    
                        a {
                            font-size: 14px;
                            padding: 20px;
                            border-bottom: 3px solid transparent;
                            white-space: nowrap;
                            
                            &:hover, .active {
                                color: $mydimm-color;
                                border-bottom: 3px solid $mydimm-color;
                            }

                            &:focus {
                                text-decoration: none;
                                color: black;
                            }
    
                            &.btn {
                                font-size: 16px;
                                margin: 10px;
                                padding: 8px 12px;
                                background-color: $mydimm-color;
                                color: $mydimm-color-text;
                                border: 1px solid $mydimm-color;

                                &:hover {
                                    background-color: $mydimm-color-hover;
                                    border-color: $mydimm-color-hover;
                                }

                                @media (max-width: 991px) {
                                    font-size: 14px;
                                    padding: 4px 6px;
                                }
                            }
                        }
    
                        &.static-text {
                            padding: 20px;
                            white-space: nowrap;
                        }

                        &:hover {
                            ul {
                                visibility: visible;
                            }
                        }

                        ul {
                            visibility: hidden;
                            position: absolute;
                            flex-direction: column;
                            align-items: flex-start;
                            min-width: 200px;
                            top: 100%;
                            left: 0;
                            padding: 0;
                            background: white;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            z-index: 1;
                            box-shadow: 2px 2px 3px #cccccc33;

                            a {
                                text-align: left;
                                border-bottom: none;
                                padding: 10px;
                                color: #999;

                                &:hover, .active {
                                    border-bottom: none;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
    
                .brand {
                    img {
                        height: 80px;
                        @media (max-width: 991px) {
                            height: 60px;
                        }
                    }
                }
            }
        }
    }

    .language-box {
        position: fixed;
        max-width: 160px;
        z-index: 9999999;
        bottom: 0;
        left: 80%;
        background-color: #212121;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        select {
            margin: 10px;
            background: none;
            border: none;
            color: #FFFFFF;
            text-transform: capitalize;
        }
    }

    body {
        position: relative;
        padding-bottom: 6rem;
        min-height: 100%;

        .content {
            min-height: 100%;
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.05);
            padding: 0em 3rem 2rem 3rem;
        }
    }

    footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1rem;

        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 400;
        color: #404040;
        text-transform: uppercase;

        a {
            text-transform: none;
        }

        small {
            font-size: 13px;
        }
    }

    a, .btn-link {
        color: #000;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: $mydimm-color;
            text-decoration: none;
        }
    }

    .well {
        border-radius: 0;
    }
}

/**
 * Button
 */
a.btn-hollow {
    font-weight: bold;
    padding: 2px 13px;
    color: $mydimm-color;
    border: 1px solid $mydimm-color;
    border-radius: 0;
    background-color: transparent;
    text-transform: uppercase;
}

a.btn-hollow:hover {
    color: $mydimm-color;
}

.btn-mydimm {
    background-color: $mydimm-color;
    color: #FFF;
}
.btn-mydimm:hover {
    color: #FFF;
}

/* NoWrap btn-group for large screen */
@media (min-width: 768px) {
    .btn-group.nowrap {
        white-space: nowrap;
    }

    .btn-group.nowrap > .btn {
        float: inherit;
    }

    .btn-group.nowrap > .btn + .btn {
        margin-left: -5px;
    }
}
/**
 * Panel
 */
.panel-mydimm {
    border-color: #f5c00f;
}

.panel-mydimm > .panel-heading {
    color: #fff;
    background-color: $mydimm-color;
    border-color: $mydimm-color;
}
/**
 * Editor
 */
.element {
    cursor: pointer;
}
/**
 * Other custom
 */
.col-mini-offset {
    margin-left: 20px;
}

table.table-no-border > tbody > tr > td {
    border-top: none;
}
