/*

Bootstrap customization for MyDimm.com
By: Creacore.be

*/

.login-page {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url("/bundles/app/img/login-background.jpg");
  background-size: auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 0 !important;
  background-position-y: 81px;
  background-position-x: center;
  min-height: 925px !important;
}

@media (max-width: 768px) {
  .login-page {
    background: none;
  }
}

.login-page .row {
  margin: 0 !important;
}

.login-page .text-right {
  text-align: right;
}

.login-page .text-center {
  text-align: center;
}

.login-page .btn-mydimm,
.login-page .btn-mydimm-alt {
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.login-page .btn-mydimm {
  color: #4d4c4a;
  background-color: #f8b01b;
}

.login-page .btn-mydimm-alt {
  background-color: #4d4c4a;
  color: #f8b01b;
}

.login-page .facebook-logo {
  background-color: #4267B2;
  padding: 5px 8px;
  color: white;
  border-radius: 5px;
}

.login-page .google-logo {
  background-color: #DB4437;
  padding: 5px;
  color: white;
  border-radius: 5px;
}

.login-page .btn-gray {
  color: black;
  background-color: #ececec;
}

.login-page header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.login-page header img {
  height: 80px;
}

.login-page header h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 768px) {
  .login-page header h1 {
    display: none;
  }
}

.login-page header .actions {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

@media (max-width: 768px) {
  .login-page header .actions {
    display: none;
  }
}

@media (max-width: 768px) {
  .login-page header {
    justify-content: center;
    flex-direction: column;
  }

  .login-page header img {
    max-height: 72px;
  }

  .login-page header h1 {
    font-size: 20px;
    margin-top: 0;
    text-align: center;
  }

  .login-page header .actions {
    margin-bottom: 1em;
  }
}

.login-page .login-cols {
  display: flex;
  padding: 5rem;
  justify-content: space-between;
  align-items: center;
  max-width: 2000px;
  margin: auto;
}

@media (max-width: 768px) {
  .login-page .login-cols {
    flex-direction: column;
  }
}

.login-page .login-text {
  line-height: 2rem;
  color: white;
  padding: 6rem 5rem;
  text-shadow: 2px 2px 5px #4d4c4a;
}

.login-page .login-text p,
.login-page .login-text li {
  font-size: 1.8rem;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .login-page .login-text {
    display: none;
  }
}

.login-page section {
  background: white;
  border-radius: 10px;
  padding: 2em;
  width: 50vw;
  max-width: 550px;
}

.login-page section label {
  text-align: left;
}

.login-page section .warn-msg {
  margin-bottom: 1em;
}

.login-page section .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
  gap: 2em;
}

@media (max-width: 768px) {
  .login-page section .head {
    display: none;
  }
}

.login-page section .head h2 {
  font-size: 22px;
}

.login-page section .head img {
  max-height: 96px;
}

.login-page section .separator {
  text-align: center;
  margin: 2em 0;
  color: #ababab;
}

.login-page section .separator::before {
  content: "";
  background-color: #ababab;
  width: 100%;
  height: 1px;
  display: block;
  margin-bottom: -.75em;
}

.login-page section .separator span {
  background-color: white;
  padding: 0 1em;
  text-transform: uppercase;
}

.login-page section .mobile-head-img {
  display: none;
  margin: 0 auto;
}

.login-page section .container {
  width: 100%;
}

@media (max-width: 768px) {
  .login-page section {
    background-color: #4d4c4aee;
    width: 100%;
    max-width: none;
    color: white;
    min-height: calc(100vh - 72px);
    margin: 0;
    border-radius: 0;
    padding: 0;
  }

  .login-page section .mobile-head-img {
    display: block !important;
    width: 100%;
  }

  .login-page section .container {
    padding: 2em;
  }

  .login-page section .container small {
    color: #aaa;
  }

  .login-page section .separator span {
    background-color: #4d4c4a;
    border-radius: 3px;
  }

  .login-page section a {
    color: #f8b01b;
  }
}

html {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 100%;
}

html .navigation {
  border-bottom: 1px solid black;
  margin-bottom: 2em;
}

html .navigation nav .nav-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

html .navigation nav .nav-container .header-left {
  justify-content: flex-start;
}

html .navigation nav .nav-container .header-center {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

html .navigation nav .nav-container .header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

html .navigation nav .nav-container ul {
  display: flex;
  align-items: center;
  margin: 0;
}

html .navigation nav .nav-container ul li {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  position: relative;
}

html .navigation nav .nav-container ul li a {
  font-size: 14px;
  padding: 20px;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
}

html .navigation nav .nav-container ul li a:hover,
html .navigation nav .nav-container ul li a .active {
  color: #F49B09;
  border-bottom: 3px solid #F49B09;
}

html .navigation nav .nav-container ul li a:focus {
  text-decoration: none;
  color: black;
}

html .navigation nav .nav-container ul li a.btn {
  font-size: 16px;
  margin: 10px;
  padding: 8px 12px;
  background-color: #F49B09;
  color: #FFFFFF;
  border: 1px solid #F49B09;
}

html .navigation nav .nav-container ul li a.btn:hover {
  background-color: #DB8A07;
  border-color: #DB8A07;
}

@media (max-width: 991px) {
  html .navigation nav .nav-container ul li a.btn {
    font-size: 14px;
    padding: 4px 6px;
  }
}

html .navigation nav .nav-container ul li.static-text {
  padding: 20px;
  white-space: nowrap;
}

html .navigation nav .nav-container ul li:hover ul {
  visibility: visible;
}

html .navigation nav .nav-container ul li ul {
  visibility: hidden;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  top: 100%;
  left: 0;
  padding: 0;
  background: white;
  border: 1px solid #eee;
  border-radius: 3px;
  z-index: 1;
  box-shadow: 2px 2px 3px #cccccc33;
}

html .navigation nav .nav-container ul li ul a {
  text-align: left;
  border-bottom: none;
  padding: 10px;
  color: #999;
}

html .navigation nav .nav-container ul li ul a:hover,
html .navigation nav .nav-container ul li ul a .active {
  border-bottom: none;
  color: #666;
}

html .navigation nav .nav-container .brand img {
  height: 80px;
}

@media (max-width: 991px) {
  html .navigation nav .nav-container .brand img {
    height: 60px;
  }
}

html .language-box {
  position: fixed;
  max-width: 160px;
  z-index: 9999999;
  bottom: 0;
  left: 80%;
  background-color: #212121;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

html .language-box select {
  margin: 10px;
  background: none;
  border: none;
  color: #FFFFFF;
  text-transform: capitalize;
}

html body {
  position: relative;
  padding-bottom: 6rem;
  min-height: 100%;
}

html body .content {
  min-height: 100%;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 0em 3rem 2rem 3rem;
}

html footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  color: #404040;
  text-transform: uppercase;
}

html footer a {
  text-transform: none;
}

html footer small {
  font-size: 13px;
}

html a,
html .btn-link {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}

html a:hover,
html .btn-link:hover {
  color: #F49B09;
  text-decoration: none;
}

html .well {
  border-radius: 0;
}

/**
 * Button
 */

a.btn-hollow {
  font-weight: bold;
  padding: 2px 13px;
  color: #F49B09;
  border: 1px solid #F49B09;
  border-radius: 0;
  background-color: transparent;
  text-transform: uppercase;
}

a.btn-hollow:hover {
  color: #F49B09;
}

.btn-mydimm {
  background-color: #F49B09;
  color: #FFF;
}

.btn-mydimm:hover {
  color: #FFF;
}

/* NoWrap btn-group for large screen */

@media (min-width: 768px) {
  .btn-group.nowrap {
    white-space: nowrap;
  }

  .btn-group.nowrap > .btn {
    float: inherit;
  }

  .btn-group.nowrap > .btn + .btn {
    margin-left: -5px;
  }
}

/**
 * Panel
 */

.panel-mydimm {
  border-color: #f5c00f;
}

.panel-mydimm > .panel-heading {
  color: #fff;
  background-color: #F49B09;
  border-color: #F49B09;
}

/**
 * Editor
 */

.element {
  cursor: pointer;
}

/**
 * Other custom
 */

.col-mini-offset {
  margin-left: 20px;
}

table.table-no-border > tbody > tr > td {
  border-top: none;
}

