/*Form Wizard*/
.bs-wizard {
    border-bottom: solid 1px #e0e0e0;
    padding: 0 0 10px;
}

.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 10px;
}

.bs-wizard > .bs-wizard-step.active > .bs-wizard-info {
    font-weight: bold;
    color: #333;
    font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #fbe8aa;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #fef8e7;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot {
    background: #fbbd19;
}

.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot:after {
    background: #fef8e7;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0;
    box-shadow: none;
    background: #fbe8aa;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}
/*END Form Wizard*/
